var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-3"},[(_vm.performingRequest)?_c('Loader'):_vm._e(),(_vm.activePayables == 'Employees')?_c('div',{staticClass:"dashboard__container--body"},[(!_vm.validEmployees || _vm.validEmployees.length == 0)?_c('Loader'):_vm._e(),_c('div',{staticClass:"flex",staticStyle:{"width":"100%","overflow":"auto"}},[(_vm.validEmployees && _vm.validEmployees.length >= 1)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.validEmployees,"styleClass":"vgt-table striped","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 20,
          },"select-options":{
            enabled: true,
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            selectOnCheckboxOnly: true,
          }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'day')?_c('span',[(props.row.day)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(props.row.day,"MMMM Do, YYYY")))]):_vm._e()]):(props.column.field == 'actions')?_c('span',[_c('div',[_c('button',{staticClass:"btn btn__small btn__primary mr-3",on:{"click":function($event){return _vm.approvePayment(props.row)}}},[_vm._v(" Approve ")]),_c('button',{staticClass:"btn btn__small btn__danger",on:{"click":function($event){return _vm.cancelEmployeePayment(props.row)}}},[_vm._v(" Cancel Payment ")])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,782825772)},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn__small btn__dark",on:{"click":function($event){return _vm.payBatch()}}},[_vm._v(" Pay Batch "),(!_vm.performingRequest)?_c('i',{staticClass:"fa-solid fa-square-dollar ml-2"}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.performingRequest)?_c('span',{staticClass:"ml-2"},[_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_vm._e()])],1)])]):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }