<template>
  <div class="pt-3">
    <Loader v-if="performingRequest" />
    <!-- <div class="dashboard__container--header">
      <div class="whiteBack flex flex-row mb-3">
        <v-select
          style="min-width: 200px;"
          label="title"
          :options="options"
          v-model="activePayables">
        </v-select>
      </div>

    </div> -->
    <div class="dashboard__container--body" v-if="activePayables == 'Employees'">
      <Loader v-if="!validEmployees || validEmployees.length == 0" />
      <div class="flex" style="width:100%; overflow: auto;">
        <vue-good-table
        @on-selected-rows-change="selectionChanged"
          v-if="validEmployees && validEmployees.length >= 1"
          :columns="columns"
          :rows="validEmployees"
          styleClass="vgt-table striped"
          :search-options="{
            enabled: true,
            placeholder: 'Search this table',
          }"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 20,
          }"
          :select-options="{
            enabled: true,
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            selectOnCheckboxOnly: true,
          }"
        >
        <div slot="selected-row-actions">

          <button class="btn btn__small btn__dark" @click="payBatch()">
            Pay Batch <i class="fa-solid fa-square-dollar ml-2" v-if="!performingRequest"></i>
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'day'">
            <span v-if="props.row.day">{{props.row.day | moment("MMMM Do, YYYY") }}</span>
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <div>
              <button class="btn btn__small btn__primary mr-3" @click="approvePayment(props.row)">
                Approve
              </button>
              <button class="btn btn__small btn__danger" @click="cancelEmployeePayment(props.row)">
                Cancel Payment
              </button>
            </div>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
        </vue-good-table>
      </div>
    </div>
<!--     <div class="dashboard__container--body" v-if="activePayables == 'Contractors'">
      <Loader v-if="!validContractors || validContractors.length == 0" />
      <div class="flex" style="width:100%; overflow: auto;">
        <vue-good-table
        @on-selected-rows-change="selectionChanged"
          v-if="validContractors && validContractors.length >= 1"
          :columns="columns"
          :rows="validContractors"
          styleClass="vgt-table striped"
          :search-options="{
            enabled: true,
            placeholder: 'Search this table',
          }"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 20,
          }"
          :select-options="{
            enabled: true,
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            selectOnCheckboxOnly: true,
          }"
        >
        <div slot="selected-row-actions">

          <button class="btn btn__small btn__dark" @click="payBatch()">
            Pay Batch <i class="fa-solid fa-square-dollar ml-2" v-if="!performingRequest"></i>
            <transition name="fade">
              <span class="ml-2" v-if="performingRequest">
              <i class="fa fa-spinner fa-spin"></i>
              </span>
            </transition>
          </button>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'day'">
            <span v-if="props.row.day">{{props.row.day | moment("MMMM Do, YYYY") }}</span>
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <div>
              <button class="btn btn__small btn__primary mr-3" @click="approvePayment(props.row)">
                Approve
              </button>
              <button class="btn btn__small btn__danger" @click="undoSchedule(props.row)">
                Cancel Payment
              </button>
            </div>
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
        </vue-good-table>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'payables',
  data: () => ({
    performingRequest: false,
    activePayables: 'Employees',
    batch: [],
    columns: [
      {
        label: 'Assignment ID',
        field: 'id',
      },
      {
        label: 'First',
        field: 'firstName',
      },
      {
        label: 'Last',
        field: 'lastName',
      },
      {
        label: 'Pay Status',
        field: 'paystatus',
      },
      {
        label: 'Pay Total',
        field: 'payTotal',
      },
      {
        label: 'Day',
        field: 'day',
      },
      {
        label: 'Order',
        field: 'eventName',
      },
      {
        label: 'Type',
        field: 'eventInfo.workerType',
      },
      {
        label: 'Actions',
        field: 'actions',
      },
    ]
  }),
  components: {
    Loader,
  },
  created() {
    if (!this.payableEmployees || this.payableEmployees.length == 0) {
      this.$store.dispatch("getPayableEmployees") 
    }
  },
  computed: {
    ...mapState(['userProfile', 'payableEmployees']),
    validEmployees() {
      if (this.payableEmployees && this.payableEmployees.length >= 1) {
        return this.payableEmployees.filter(item => {
          return item && item.id
        })
      }
    }
  },
  methods: {
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    async approvePayment(row) {
      this.performingRequest = true
      await this.$store.dispatch('requestPayment', row)
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
    async payBatch() {
      this.performingRequest = true
      await this.batch.forEach(item => {
        console.log(item)
        this.$store.dispatch('requestPayments', {
          id: item.userId,
          type: item.eventDetails.shiftType
        })
        // payArray.push({
        //   id: item.userId,
        //   type: item.eventInfo.workerType,
        //   assignment: item.id,
        //   verified: false
        // })
      })
      // console.log(payArray)
      // await this.$store.dispatch('requestPayment', payArray)
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },

    async cancelEmployeePayment(row, index) {
      console.log(row)
      await this.$store.dispatch("deletePayable", row)
      let payableEmployees = this.payableEmployees
      payableEmployees.splice(index, 1)
       
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;

        return moment.utc(postedDate).local().format('HH:mm:ss A')
        // return moment(postedDate).format('HH:MM A')
      } else {
        return null
      }
    },
  
  },
  beforeDestroy() {
    this.$store.dispatch("clearPayables")
    console.log(this)
  }
}
	
</script>